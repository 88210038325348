import React, { useEffect } from "react"

const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/embed/v2.js"
    document.body.appendChild(script)
    script.addEventListener("load", () => {
      // @ts-ignore
      window.hbspt.forms.create({
        portalId: "8445907",
        formId: "8712f012-7266-45c2-a6ce-ee0a1c15d853",
        target: "#hubspotForm"
      })
    })
  }, [])

  return (
    <div>
      <div id="hubspotForm" style={{ position: "relative" }} />
    </div>
  )
}

export default HubspotContactForm
import React, { useCallback } from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { useBoolean, useInput } from 'react-hanger'
import toast from 'react-hot-toast'
import InputMask from 'react-input-mask'

import * as styles from '../../styles/pages/contactSales.module.scss'

import { FiUser, FiPhone, FiSend, FiMail, FiBriefcase } from 'react-icons/fi'
import { CgSpinner } from 'react-icons/cg'

import { Layout } from '../../components/layout'

import i18n from '../../i18n'
import { SEO } from '../../components/SEO'
import {
  actionList,
  actionListType,
} from '../../components/contactModal/actions'
import { LinkCustom } from '../../components/contactModal/contactActions'

import { api } from '../../utils/api'
import { Button } from '../../components/button'
import HubspotContactForm from '../../components/hubspotContactForm'

const ContactSales: React.FC<PageProps> = ({ location }) => {
  const isInSale = !!process.env.GATSBY_IS_ON_SALE

  const loading = useBoolean(false)
  const name = useInput(``)
  const email = useInput(``)
  const phone = useInput(``)
  const businessName = useInput(``)
  const messageText = useInput(``)

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        await api.post(`1/website/contactForm`, {
          name: name.value,
          email: email.value,
          phone: phone.value,
          businessName: businessName.value,
          message: messageText.value,
        })
        toast.success(`${i18n[`common.allRight`]}`)
        name.clear()
        email.clear()
        phone.clear()
        businessName.clear()
        messageText.clear()
      } catch (error) {
        toast.error(`${i18n[`common.errorTryAgain`]}`)
        console.error(error)
      } finally {
        loading.setFalse()
      }
    },
    [loading, name, email, phone, businessName, messageText]
  )

  return (
    <Layout
      whiteHeader={true}
      isLargeFooter={false}
      isHome={false}
      location={location}
    >
      <SEO title="Entre em contato - Roberty Automation" />
      <section className={styles.sales}>
        <div
          className={clsx(styles.initialWrapper, {
            [styles.hasSales]: isInSale,
          })}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-10 col-lg-6 ">
                <div className={styles.title}>
                  <h1>
                    {i18n[`common.interested`]}
                    <br />
                    <span>{i18n[`common.letsTalk`]}</span>
                  </h1>
                  <p>
                    {i18n[`common.optionsTalk`]}
                    <br />
                    {i18n[`common.prefer`]} {` `}
                    {i18n[`common.weContactYou`]}
                  </p>
                </div>
                <div className={styles.options}>
                  {actionList.map((item: actionListType, index) => (
                    <div className={styles.option} key={index}>
                      <LinkCustom
                        item={item}
                        isFunction={item.actionLink ? false : true}
                      >
                        <div
                          className={clsx(styles.actionItem, {
                            [styles.call]: item.type === 'call',
                            [styles.schedule]: item.type === 'schedule',
                            [styles.whats]: item.type === 'whats',
                          })}
                        >
                          <div className={styles.actionIcon}>{item.icon}</div>
                          <div className={styles.actionContent}>
                            <span>{item.actionText}</span>
                          </div>
                        </div>
                      </LinkCustom>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-sm-10 col-lg-6">
                <div className={styles.mainForm}>
                  <div className={styles.title}>
                    <h1>{i18n[`common.contactUs`]}</h1>
                    <p>
                      {i18n[`common.feedback`]}
                      <br />
                      {i18n[`common.fillForm`]}
                    </p>
                  </div>
                  <HubspotContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactSales

// extracted by mini-css-extract-plugin
export var actionContent = "contactSales-module--actionContent--kcFi7";
export var actionIcon = "contactSales-module--actionIcon--107hG";
export var actionItem = "contactSales-module--actionItem--G-zc0";
export var blueGradient = "contactSales-module--blue-gradient--8A8pt";
export var blueGradientCitric = "contactSales-module--blue-gradient-citric--BirF9";
export var buttonAction = "contactSales-module--buttonAction--c4p5s";
export var darkBlueGradient = "contactSales-module--dark-blue-gradient--VtZCp";
export var darkGradient = "contactSales-module--dark-gradient--AT3HS";
export var hasSales = "contactSales-module--hasSales--xEHAS";
export var initialWrapper = "contactSales-module--initialWrapper--lpfA1";
export var lightGradient = "contactSales-module--light-gradient--3PUl7";
export var mainForm = "contactSales-module--mainForm--AFOxI";
export var option = "contactSales-module--option--mGhCl";
export var options = "contactSales-module--options--nH6WB";
export var purpleBlueGradient = "contactSales-module--purple-blue-gradient--fawQU";
export var sales = "contactSales-module--sales--9UFtQ";
export var softBlueGradient = "contactSales-module--soft-blue-gradient---TOYu";
export var softGreyGradient = "contactSales-module--soft-grey-gradient--yuyzL";
export var title = "contactSales-module--title--0A9Hu";